import {
    Box, BoxProps,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
    Tooltip, Typography, TypographyProps
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {InfoTypography, InfoValueTypography} from "./UserPage";
import {useAuth} from "../context/auth/useAuth";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import {TwitterAccountData, XStatistic} from "../types/TwitterTypes";
import {ErrorModal} from "../common/alerts/ErrorModal";
import {DifferenceSpan} from "../common/span/DifferenceSpan";
import {
    addTwitterChatMessage,
    deleteChatMessage,
    deleteTwitterAccount, getAccountStatistic,
    getTwitterAccount,
    run,
    stop,
    updateSubscription, updateTwitterAccount, updateTwitterAccountSpeed
} from "../../services/TwitterService";

import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import {Loading} from "../common/loading/Loading";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import {TablePaper} from "../common/twitter/TwitterAccountTable";

type TwitterAccountPageParams = {
    id: string
}

const DataBox = styled(Box)<BoxProps>(({theme}) => ({
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "start",
    width: "45vh",
    [theme.breakpoints.down('md')]: {
        mt: "2rem",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: "2rem"
    },
    [theme.breakpoints.down('sm')]: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: "2rem"
    },
    [theme.breakpoints.only('xs')]: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: "2rem"
    },
}));

const DataStatBox = styled(Box)<BoxProps>(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    [theme.breakpoints.down('md')]: {
        display: "block",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
        // display: "block",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
    [theme.breakpoints.only('xs')]: {
        // display: "block",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
}));

const MessageBox = styled(Box)<BoxProps>(({theme}) => ({
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    width: "100%",
    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        // flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        marginTop: "2rem"
    },
    [theme.breakpoints.down('sm')]: {
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        marginTop: "2rem"
    },
    [theme.breakpoints.only('xs')]: {
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        marginTop: "2rem"
    },
}));

const TypoMessageBox = styled(Box)<BoxProps>(({theme}) => ({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    [theme.breakpoints.down('md')]: {
        width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
        width: "100%",
    },
    [theme.breakpoints.only('xs')]: {
        width: "100%",
    },
}));
const MessageItemBox = styled(Box)<BoxProps>(({theme}) => ({
    width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center",
    [theme.breakpoints.down('md')]: {
        width: "100%",
        // alignItems:"center",
    },
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        // alignItems:"center",
    },
    [theme.breakpoints.only('xs')]: {
        width: "100%",
        // alignItems:"center",
    },
}));

export const MsgTypography = styled(Typography)<TypographyProps>(({theme}) => ({
    fontSize: "1rem",
    width: "100%",
    maxWidth: "351px",
    [theme.breakpoints.down('md')]: {
        // fontSize: "0.5rem",
        width: "100%",
        maxWidth: "400px"
    },
    [theme.breakpoints.down('sm')]: {
        // fontSize: "0.2rem",
        width: "100%",
        maxWidth: "300px"
    },
    [theme.breakpoints.only('xs')]: {
        // fontSize: "0.1rem",
        width: "100%",
        maxWidth: "300px"
    },
    [theme.breakpoints.down('xs')]: {
        // fontSize: "0.1rem",
        width: "100%",
        maxWidth: "250px"
    },
}));

export const InstructionTypography = styled(Typography)<TypographyProps>(({theme}) => ({
    fontSize: "1rem",
    width: "100%",
    maxWidth: "941px",
    [theme.breakpoints.down('md')]: {
        // fontSize: "0.5rem",
        width: "100%",
        maxWidth: "400px"
    },
    [theme.breakpoints.down('sm')]: {
        // fontSize: "0.2rem",
        width: "100%",
        maxWidth: "300px"
    },
    [theme.breakpoints.only('xs')]: {
        // fontSize: "0.1rem",
        width: "100%",
        maxWidth: "300px"
    },
    [theme.breakpoints.down('xs')]: {
        // fontSize: "0.1rem",
        width: "100%",
        maxWidth: "250px"
    },
}));


export const TwitterAccountPage = () => {
    const {id} = useParams<TwitterAccountPageParams>();
    const {jwt} = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [twitterAccount, setTwitterAccount] = useState<TwitterAccountData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [month, setMonth] = useState<number>();
    const [newMessage, setNewMessage] = useState<string>();
    const [gifUrl, setGifUrl] = useState<string | null>();
    const [statistic, setStatistic] = useState<XStatistic>();
    const [username, setUsername] = useState<string>();
    const [proxy, setProxy] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [auth, setAuth] = useState<string>();
    const [ct0, setCt0] = useState<string>();
    const [speed, setSpeed] = useState<number>();
    const [instructionIsOpened, setInstructionIsOpened] = useState<boolean>(false);

    useEffect(() => {
        refetchData();
    }, [jwt, id])

    const refetchData = () => {
        if (jwt && id) {
            const response = getTwitterAccount({id: +id!, token: jwt!.accessToken});
            response.then(data => {
                setTwitterAccount(data);
                setUsername(data.username);
                setProxy(data.proxy);
                setEmail(data.email);
                setPassword(data.password)
            }).catch(err => {
                if (err.response.status === 403) {
                    navigate("/login");
                } else {
                    setError(true);
                    setErrorText(err.response.data.errorMessages);
                }
            })
        }
    };
    const handleOpenError = () => {
        setError(true);
    }
    const handleCloseError = () => {
        setError(false);
    }

    const handleUpdateLoading = () => {
        setIsLoading(oldStateIsLoading => !oldStateIsLoading);
    }

    const handleRunApp = (id: number): void => {
        handleUpdateLoading();
        const response = run({token: jwt!.accessToken, id: id});
        response.then(() => {
            handleUpdateLoading();
            refetchData();
        }).catch(err => {
            handleUpdateLoading();
            if (err.response.status === 403) {
                navigate("/login");
            } else {
                handleOpenError();
                setErrorText(err.response.data.errorMessages);
            }
        });
    };
    const handleStopApp = (id: number): void => {
        handleUpdateLoading();
        const response = stop({token: jwt!.accessToken, id: id});
        response.then(() => {
            handleUpdateLoading();
            refetchData();
        }).catch(err => {
            handleUpdateLoading();
            if (err.response.status === 403) {
                navigate("/login");
            } else {
                handleOpenError();
                setErrorText(err.response.data.errorMessages);
            }
        });
    };

    const handleDeleteTwitterAccount = (id: number): void => {
        const response = deleteTwitterAccount({token: jwt!.accessToken, id: id});
        response.then(() => {
            handleUpdateLoading();
            navigate("/user")
        }).catch(err => {
            handleUpdateLoading();
            if (err.response.status === 403) {
                navigate("/login");
            } else {
                handleOpenError();
                setErrorText(err.response.data.errorMessages);
            }
        });
    };

    const handleOpenStatistic = () => {
        handleUpdateLoading();
        const resp = getAccountStatistic({id: +id!, token: jwt!.accessToken});
        resp.then(data => {
            handleUpdateLoading();
            setStatistic(data);
        }).catch(err => {
            handleUpdateLoading();
            if (err.response.status === 403) {
                navigate("/login");
            } else {
                setError(true);
                setErrorText(err.response.data.errorMessages);
            }
        });
    };

    const handleUpdatedTwitterAccountSpeed = () => {
        const resp = updateTwitterAccountSpeed({auth: jwt!.accessToken, id: +id!, speed: +speed!});
        resp.then(data => {
            handleUpdateLoading();
            window.location.reload();
            refetchData();
        }).catch(err => {
            handleUpdateLoading();
            if (err.response.status === 403) {
                navigate("/login");
            } else {
                setError(true);
                setErrorText(err.response.data.errorMessages);
            }
        });
    }

    const handleChangeMonth = (event: SelectChangeEvent) => {
        // event.preventDefault()
        setMonth(+event.target.value);
    };

    const handleChangeSpeed = (event: SelectChangeEvent) => {
        // event.preventDefault()
        setSpeed(+event.target.value);
    };

    const handleSubmit = () => {
        const response = updateSubscription({id: +id!, month: month!, token: jwt!.accessToken});
        response.then(data => {
            window.location.reload();
            refetchData();
        }).catch(err => {
            if (err.response.status === 403) {
                navigate("/login");
            }
        })
    };

    const handleDeleteMessage = (id: number) => {
        const response = deleteChatMessage({token: jwt!.accessToken, id: id!,});
        response.then(data => {
            refetchData();
        }).catch(err => {
            if (err.response.status === 403) {
                navigate("/login");
            }
        })
    };

    const handleChangeNewMessage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setNewMessage(event.target.value);
    };

    const handleChangeGifUrl = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setGifUrl(event.target.value);
    };

    const handleAddMessage = () => {
        const response = addTwitterChatMessage({id: +id!, message: newMessage!, gif: gifUrl!, token: jwt!.accessToken});
        response.then(data => {
            window.location.reload();
            refetchData();
        }).catch(err => {
            if (err.response.status === 403) {
                navigate("/login");
            }
        })
    };

    const handleUpdateTwitterAccount = () => {
        const updatedData: any = {};

        if (username !== twitterAccount!.username) {
            updatedData.username = username;
        }
        if (proxy !== twitterAccount!.proxy) {
            updatedData.proxy = proxy;
        }
        if (email !== twitterAccount!.email) {
            updatedData.email = email;
        }
        if (password !== twitterAccount!.password) {
            updatedData.password = password;
        }
        if (auth) {
            updatedData.authToken = auth;
        }
        if (ct0) {
            updatedData.csrfToken = ct0;
        }
        if (Object.keys(updatedData).length === 0) {
            console.log("No changes to update");
            return;
        }

        const response = updateTwitterAccount({
            id: +id!,
            ...updatedData,
            token: jwt!.accessToken
        });

        response.then(() => {
            window.location.reload();
            refetchData();
        }).catch(err => {
            if (err.response.status === 403) {
                navigate("/login");
            } else {
                setError(true);
                setErrorText(err.response.data.errorMessages);
            }
        });
    };

    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUsername(event.target.value)
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value)
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(event.target.value)
    }

    const handleChangeAuth = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAuth(event.target.value)
    }

    const handleChangeCt0 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCt0(event.target.value)
    }

    const handleChangeProxy = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProxy(event.target.value)
    }

    const handleOpenInstruction = () => {
        setInstructionIsOpened(oldStateIsLoading => !oldStateIsLoading);
    }

    return (
        <Box>
            {twitterAccount ?
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "3rem"
                }}>
                    {isLoading ? <Loading/> : null}
                    {instructionIsOpened ?
                        <Box sx={{
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <InstructionTypography sx={{marginTop: "2rem", width: "100%"}}>
                                1. To turn on statistic follow the
                                <Tooltip title={`https://analytics.twitter.com/user/${twitterAccount!.username}/tweets`}>
                                    <Link
                                        href={`https://analytics.twitter.com/user/${twitterAccount!.username}/tweets`}>
                                        {} link {}
                                    </Link>
                                </Tooltip>
                                from any browser in which the Twitter account is logged in and click the "Turn analytics
                                on" button, then click the Statistic button in the tool panel
                            </InstructionTypography>
                            <InstructionTypography sx={{width: "100%"}}>2. Proxy format :
                                http://ip:port@username:password /
                                socks5://ip:port@username:password</InstructionTypography>
                            <InstructionTypography sx={{width: "100%"}}>3. To change data, replace fields with a new
                                data and
                                press update button in tool panel</InstructionTypography>
                            <InstructionTypography sx={{width: "100%"}}>4. If you have UNPAID status, update
                                subscription at
                                window right section select and restart account</InstructionTypography>
                            <InstructionTypography sx={{width: "100%"}}>5. If you have UNXPECTED_ERROR status, try to
                                restart
                                account, this is usually caused by twitter errors</InstructionTypography>
                            <InstructionTypography sx={{width: "100%"}}>6. If you have INVALID_COOKIES status, updat
                                auth token
                                and ct0 and restart account</InstructionTypography>
                            <InstructionTypography sx={{width: "100%"}}>7. If you have LOCKED status, solve captcha at
                                twitter
                                account, update auth token and ct0 and restart account</InstructionTypography>
                        </Box>
                        : null}
                    <Box sx={{display: "flex", justifyContent: "center", mt: "2rem", mb: "2rem"}}>
                        <Tooltip title="Instruction">
                            <IconButton onClick={() => handleOpenInstruction()}>
                                <LiveHelpIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Statistic">
                            <IconButton onClick={() => handleOpenStatistic()}>
                                <AnalyticsIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Start">
                            <IconButton onClick={() => handleRunApp(twitterAccount!.id)}
                                        disabled={twitterAccount!.status === 'ACTIVE' || twitterAccount!.status === 'COOLDOWN'}>
                                <PlayArrowIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Stop">
                            <IconButton onClick={() => handleStopApp(twitterAccount!.id)}>
                                <StopIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Update">
                            <IconButton onClick={handleUpdateTwitterAccount}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton color="error"
                                        onClick={() => handleDeleteTwitterAccount(twitterAccount!.id)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <DataStatBox>
                        <DataBox>
                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Username :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={username}
                                    fullWidth
                                    onChange={(event) => handleChangeUsername(event)}
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Email :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={email}
                                    fullWidth
                                    onChange={(event) => handleChangeEmail(event)}
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Password :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={password}
                                    fullWidth
                                    onChange={(event) => handleChangePassword(event)}
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Proxy :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={proxy}
                                    fullWidth
                                    onChange={(event) => handleChangeProxy(event)}
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Auth token :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={auth}
                                    fullWidth
                                    onChange={(event) => handleChangeAuth(event)}
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Ct0 :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={ct0}
                                    fullWidth
                                    onChange={(event) => handleChangeCt0(event)}
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Paid to :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={twitterAccount!.paidTo}
                                    fullWidth
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Status :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={twitterAccount!.status}
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            fontSize: '20px',
                                            marginLeft: "5px",
                                            color: twitterAccount!.status === 'ACTIVE' ? '#0fb886'
                                                : twitterAccount!.status === 'LOCKED' ? '#fa5352'
                                                    : twitterAccount!.status === 'UNEXPECTED ERROR' ? '#fa5352'
                                                        : twitterAccount!.status === 'PROXY ERROR' ? '#fa5352'
                                                            : twitterAccount!.status === 'SUSPENDED' ? '#fa5352'
                                                                : twitterAccount!.status === 'INVALID COOKIES' ? '#fa5352'
                                                                    : twitterAccount!.status === 'Unable to connect' ? '#fa5352'
                                                                        : twitterAccount!.status === 'COOLDOWN' ? '#e3721b'
                                                                            : twitterAccount!.status === 'STOPPING' ? '#e3d21b'
                                                                                : twitterAccount!.status === 'DISABLED' ? 'grey'
                                                                                    : 'grey'
                                        }
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Groups :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={twitterAccount!.groups}
                                    fullWidth
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Followers :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={twitterAccount!.friends}
                                    fullWidth
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Speed :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={twitterAccount!.speed === 1 ? "SAFE" : twitterAccount!.speed}
                                    fullWidth
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            <Box sx={{display: "block", alignItems: "center"}}>
                                <InfoTypography>Sent messages :
                                </InfoTypography>
                                <TextField
                                    variant="standard"
                                    value={twitterAccount!.messages}
                                    fullWidth
                                    InputProps={{
                                        style: {fontSize: '20px', marginLeft: "5px", color: "grey"}
                                    }}/>
                            </Box>

                            {/*<InfoTypography>Paid to : <InfoValueTypography color="grey"*/}
                            {/*                                               component="span">{twitterAccount!.paidTo}</InfoValueTypography>*/}
                            {/*</InfoTypography>*/}
                            {/*<InfoTypography>Status : <InfoValueTypography color="grey"*/}
                            {/*                                              component="span">{twitterAccount!.status}</InfoValueTypography>*/}
                            {/*</InfoTypography>*/}
                            {/*<InfoTypography>Groups : <InfoValueTypography color="grey"*/}
                            {/*                                              component="span">{twitterAccount!.groups}</InfoValueTypography>*/}
                            {/*</InfoTypography>*/}
                            {/*<InfoTypography>Followers : <InfoValueTypography color="grey"*/}
                            {/*                                                 component="span">{twitterAccount!.friends}</InfoValueTypography>*/}
                            {/*</InfoTypography>*/}
                            {/*<InfoTypography>Speed : <InfoValueTypography color="grey"*/}
                            {/*                                                 component="span">{twitterAccount!.speed}</InfoValueTypography>*/}
                            {/*</InfoTypography>*/}
                            {/*<InfoTypography sx={{display: "flex"}}>Sent messages : <InfoValueTypography*/}
                            {/*    color="grey"*/}
                            {/*    component="span"*/}
                            {/*    sx={{display: "flex"}}*/}
                            {/*>*/}
                            {/*    {twitterAccount!.messages ? twitterAccount!.messages : "-"}</InfoValueTypography>*/}
                            {/*    <DifferenceSpan num={twitterAccount!.messagesDifference}/>*/}
                            {/*</InfoTypography>*/}

                        </DataBox>
                        <DataBox>
                            <MessageBox
                            >
                                <TextField
                                    label="New Message"
                                    multiline
                                    fullWidth
                                    value={newMessage}
                                    onChange={handleChangeNewMessage}
                                    sx={{
                                        "& label.Mui-focused": {
                                            color: "#fff"
                                        }, "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#fff"
                                            }
                                        }, input: {
                                            "&:-webkit-autofill": {
                                                WebkitBoxShadow: "0 0 0 1000px #000000 inset"
                                            }
                                        }
                                    }}
                                />
                                <TextField
                                    label="GIF url"
                                    fullWidth
                                    value={gifUrl}
                                    onChange={handleChangeGifUrl}
                                    sx={{
                                        "& label.Mui-focused": {
                                            color: "#fff"
                                        }, "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#fff"
                                            }
                                        }, input: {
                                            "&:-webkit-autofill": {
                                                WebkitBoxShadow: "0 0 0 1000px #000000 inset"
                                            }
                                        }
                                    }}
                                />
                                <IconButton onClick={handleAddMessage}>
                                    <Tooltip title="Add">
                                        <AddIcon/>
                                    </Tooltip>
                                </IconButton>
                            </MessageBox>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                {twitterAccount!.chatMessages.map((msg) => (
                                    <Box key={msg.id} sx={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        padding: "5px"
                                    }}>
                                        <TypoMessageBox>
                                            <MsgTypography sx={{
                                                padding: "5px",
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>Message : {msg.text}</MsgTypography>
                                            <MsgTypography sx={{
                                                padding: "5px",
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>Gif : {msg.gifUrl}</MsgTypography>
                                        </TypoMessageBox>
                                        <Box>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleDeleteMessage(msg.id)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>

                            <MessageItemBox>
                                <Link sx={{marginTop: "2rem"}}
                                      href="https://help.twitter.com/en/rules-and-policies/x-limits">Twitter
                                    limits</Link>
                                <Box
                                     sx={{
                                         display: "flex",
                                         alignItems: "center",
                                         justifyContent: "center",
                                         marginTop: "2rem",
                                         width: "100%"
                                     }}>

                                    <FormControl>
                                        <InputLabel id="Change speed" sx={{
                                            color: "#fff",
                                            "&.Mui-focused": {
                                                color: "#fff"
                                            }
                                        }}>Change speed</InputLabel>

                                        <Select
                                            value={String(speed)}
                                            label="Change speed"
                                            fullWidth
                                            onChange={handleChangeSpeed}
                                            sx={{
                                                width: "250px",
                                                color: "white",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#656565',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#fff',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#fff',
                                                },
                                                '.MuiSvgIcon-root ': {
                                                    fill: "white !important",
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>Safe Mode</MenuItem>
                                            <MenuItem value={32}>32 Retweets/30min</MenuItem>
                                            <MenuItem value={35}>35 Retweets/30min</MenuItem>
                                            <MenuItem value={38}>38 Retweets/30min</MenuItem>
                                            <MenuItem value={41}>41 Retweets/30min</MenuItem>
                                            <MenuItem value={44}>44 Retweets/30min</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Tooltip title="Change">
                                        <IconButton onClick={handleUpdatedTwitterAccountSpeed} sx={{marginLeft: "0.5em"}}>
                                            <RefreshIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </MessageItemBox>


                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "2rem",
                                    width: "100%"
                                }}>

                                <FormControl>
                                    <InputLabel id="Updated subscription" sx={{
                                        color: "#fff",
                                        "&.Mui-focused": {
                                            color: "#fff"
                                        }
                                    }}>Updated subscription</InputLabel>

                                    <Select
                                        value={String(month)}
                                        fullWidth
                                        label="Updated subscription"
                                        onChange={handleChangeMonth}
                                        sx={{
                                            width: "250px",
                                            color: "white",
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#656565',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fff',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fff',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "white !important",
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>30Days = 29$</MenuItem>
                                        <MenuItem value={2}>60Days = 58$</MenuItem>
                                        <MenuItem value={3}>90Days = 87$</MenuItem>
                                        <MenuItem value={6}>180Days = 174$</MenuItem>
                                        <MenuItem value={12}>360Days = 348$</MenuItem>
                                    </Select>
                                </FormControl>

                                <Tooltip title="Update">
                                    <IconButton onClick={handleSubmit} sx={{marginLeft: "0.5em"}}>
                                        <RefreshIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        </DataBox>
                    </DataStatBox>
                    {statistic ? <Box sx={{width: "100%"}}>
                            <TablePaper sx={{width: "100%", height: "100%", marginTop: "2rem", marginBottom: "2rem"}}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell align="right">Retweets</TableCell>
                                                <TableCell align="right">Link Clicks</TableCell>
                                                <TableCell align="right">Likes</TableCell>
                                                <TableCell align="right">Replies</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {statistic ? statistic!.xAccountStatistic.timeSeries.retweets.map((value, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {/*{new Date(Date.now() - ((statistic!.timeSeries.retweets.length - (index+1)) * 86400000)).toLocaleDateString()}*/}
                                                        {new Date((statistic!.xAccountStatistic.endTime) - ((statistic!.xAccountStatistic.timeSeries.retweets.length - (index + 1)) * 86400000)).toLocaleDateString('ru-RU', {timeZone: statistic.xAccountTimeZone.data.account.timezone})}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right">{statistic!.xAccountStatistic.timeSeries.retweets[index]}</TableCell>
                                                    <TableCell
                                                        align="right">{statistic!.xAccountStatistic.timeSeries.urlClicks[index]}</TableCell>
                                                    <TableCell
                                                        align="right">{statistic!.xAccountStatistic.timeSeries.favorites[index]}</TableCell>
                                                    <TableCell
                                                        align="right">{statistic!.xAccountStatistic.timeSeries.replies[index]}</TableCell>
                                                </TableRow>
                                            )) : <Loading/>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TablePaper>
                        </Box> :
                        <Box sx={{width: "100%", display: "flex", justifyContent: "center", alignItems: " center"}}>

                        </Box>}
                </Box> : null}

            {error ? <ErrorModal closeModal={handleCloseError} open={error} text={errorText!}/> : null}
        </Box>
    );
};
